import { all } from 'redux-saga/effects'
import rooms from './rooms/sagas'
import room from './room/sagas'
import users from './users/sagas'
import meeting from './meeting/sagas'
import user from './user/sagas'
import auth from './auth/sagas'
import shifts from './shifts/sagas'
import hotels from './hotels/sagas'
import allocations from './allocations/sagas'
import notes from './notes/sagas'

export default function* rootSaga(): Generator {
  yield all([
    users(),
    rooms(),
    room(),
    auth(),
    meeting(),
    user(),
    shifts(),
    hotels(),
    allocations(),
    notes(),
  ])
}
