import { AnyAction } from 'redux'
import { toast } from 'react-toastify'
import { takeLatest, put, call, take, spawn, race } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/types'
import { setState as setStateNote } from '@/redux/notes/actions'
import { fetchOne, getRoomPinnedNotes } from '@/services/rooms'
import messages from '@/config/messages'
import { IS_DEV } from '@/config/constants'
import actions, { loading, setState as setStateRoom } from './actions'
import eventChannel from './websocket'

export function* getOne({ payload }: AnyAction): SagaIterator {
  yield put(loading(true))

  try {
    const { data: room } = yield call(fetchOne, payload.id)
    const { data: docs } = yield call(getRoomPinnedNotes, payload.id)
    yield put(
      setStateRoom({
        room,
        pinned_note: docs,
      }),
    )
    yield put(setStateNote({ docs: [] }))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

function* listenRoomSaga({ id }: AnyAction): SagaIterator {
  const roomDetailsChannel = yield call(eventChannel, id)
  try {
    while (true) {
      const action = yield take(roomDetailsChannel)
      yield put(action)
    }
  } catch (e) {
    if (IS_DEV) {
      // eslint-disable-next-line no-console
      console.log('listenRoomSagaError', {
        message: (e as Error).message,
      })
    }
    toast.error(messages.socketError)
  } finally {
    roomDetailsChannel?.close?.()
  }
}

export function* websocketChannel(): SagaIterator {
  while (true) {
    const action = yield take(actions.START_ROOM_DETAILS_SOCKET_CHANNEL)
    try {
      yield race({
        task: call(listenRoomSaga, action),
        cancelTask: take(actions.CLOSE_ROOM_DETAILS_SOCKET_CHANNEL),
      })
    } catch (e) {
      if (IS_DEV) {
        // eslint-disable-next-line no-console
        console.log('watchRoomSagaError', {
          message: (e as Error).message,
        })
      }
      toast.error(messages.socketError)
    }
  }
}

export default function* rootSaga(): SagaIterator {
  yield spawn(websocketChannel)
  yield takeLatest(actions.GET_ONE, getOne)
}
