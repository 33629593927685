import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIconType } from '../types'

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}))

const RefreshIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0 0 18 18',
  stroke = '#1B1C1D',
  ...rest
}: SvgIconType) => {
  const classes = useStyles()
  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <path
        d="M1.58693 9.00629C1.58567 7.51836 2.03221 6.06448 2.86846 4.83379C3.70472 3.60309 4.89198 2.65253 6.27581 2.10577C7.65964 1.559 9.17599 1.44133 10.6276 1.76807C12.0792 2.09481 13.3989 2.85083 14.415 3.93778"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        d="M16.4121 8.99398C16.4133 10.4601 15.9797 11.8937 15.1662 13.1134C14.3526 14.3331 13.1957 15.2842 11.8416 15.8463C10.4875 16.4085 8.99718 16.5565 7.55899 16.2717C6.1208 15.9868 4.79937 15.2819 3.76182 14.246"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        d="M14.6875 1.41693L14.6875 4.26059L11.8438 4.26059"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.3125 16.5831L3.3125 13.7394L6.15616 13.7394"
        stroke={stroke}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default RefreshIcon
