import { END, eventChannel, EventChannel } from 'redux-saga'
import { Socket } from 'socket.io-client'
import oAuth from '@DevimaSolutions/o-auth'
import { createSocketConnection } from '@/config/websocket'
import { updateActiveRoomBySocket } from '@/redux/room/actions'
import { IS_DEV } from '@/config/constants'
import { updateRoomsBySocket } from './actions'

export default async (id: string): Promise<EventChannel<Socket>> => {
  const socket = await createSocketConnection(`${id}/rooms`)
  return eventChannel((emitter) => {
    socket.on('rooms-updated', (e) => {
      emitter(updateRoomsBySocket(e))
      emitter(updateActiveRoomBySocket(e))
    })
    socket.on('disconnect', (reasons) => {
      if (IS_DEV) {
        // eslint-disable-next-line no-console
        console.log('onRoomsChannelDisconnect', {
          message: reasons,
        })
      }
      emitter(END)
    })
    return () => {
      oAuth().socketManager.disconnect(socket)
    }
  })
}
