import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIconType } from '../types'

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}))

const PhoneIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 18, 18',
  ...rest
}: SvgIconType) => {
  const classes = useStyles()
  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <path
        d="M4.76655 1.4569L3.3403 1.68818C2.27817 1.86042 1.53048 2.8471 1.78057 3.89364C3.25026 10.0438 7.95629 14.7498 14.1064 16.2195C15.153 16.4696 16.1396 15.7219 16.3119 14.6598L16.5431 13.2335C16.6455 12.602 16.3411 11.9741 15.7819 11.6634L13.4516 10.3688C12.8591 10.0396 12.1201 10.1431 11.6408 10.6224L10.6742 11.589C8.86014 10.4397 7.54489 9.15063 6.41102 7.32582L7.37763 6.3592C7.85692 5.87991 7.96041 5.14097 7.63123 4.54845L6.33665 2.21819C6.02596 1.65895 5.39804 1.3545 4.76655 1.4569Z"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default PhoneIcon
