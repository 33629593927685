import OAuth from '@DevimaSolutions/o-auth'
import { HotelType, HotelResponseType, HotelFloorType, RoomType } from '@/redux/hotels/types'
import { AxiosResponse } from 'axios'
import {
  IGetHotelGroupsResponse,
  IGetHotelsRequest,
  IGetHotelParsersResponse,
} from '@/redux/hotels/actions'
import { IAddHotelForm } from '@/views/AddHotel/types'
import { IEditGeneralHotelForm } from '@/views/EditHotel/components/GeneralTab/types'
import { IEditFloorsHotelForm } from '@/views/EditHotel/components/FloorsTab/types'
import { IEditHotelRoomTypeTabForm } from '@/views/EditHotel/components/RoomTypeTab/types'

export const fetchOne = (): Promise<AxiosResponse<HotelType>> => {
  return OAuth().axios.get(`/api/manager/hotel`)
}

export const fetchOneById = (id: string): Promise<AxiosResponse<HotelType>> => {
  return OAuth().axios.get(`/api/admin/hotels/${id}`)
}

export const fetchFloorsById = (id: string): Promise<AxiosResponse<HotelFloorType[]>> => {
  return OAuth().axios.get(`/api/admin/hotels/${id}/floors`)
}

export const fetchFloorWithRooms = (
  hotelId: string,
  floor: number,
): Promise<AxiosResponse<RoomType[]>> => {
  return OAuth().axios.get(`/api/admin/hotels/floor/${hotelId}/${floor}`)
}

export const getHotels = ({
  page,
  limit,
  search,
  shortFormat,
}: IGetHotelsRequest): Promise<AxiosResponse<HotelResponseType>> => {
  return OAuth().axios.get('/api/admin/hotels', {
    params: {
      page: page + 1,
      limit,
      search,
      shortFormat,
    },
  })
}

export const addNewHotel = (data: IAddHotelForm): Promise<AxiosResponse> => {
  return OAuth().axios.post('/api/admin/hotels', data)
}

export const editGeneralHotel = (
  id: string,
  data: IEditGeneralHotelForm,
): Promise<AxiosResponse> => {
  return OAuth().axios.put(`/api/admin/hotels/${id}`, data)
}

export const editHotelFloors = (id: string, data: IEditFloorsHotelForm): Promise<AxiosResponse> => {
  return OAuth().axios.post(`/api/admin/hotels/floor/${id}`, data)
}

export const editHotelRoomsType = (data: IEditHotelRoomTypeTabForm): Promise<AxiosResponse> => {
  return OAuth().axios.put(`/api/admin/hotels/rooms/`, data)
}

export const deleteHotel = (id: string): Promise<AxiosResponse> => {
  return OAuth().axios.delete(`/api/admin/hotels/${id}`)
}

export const getHotelGroups = (): Promise<AxiosResponse<IGetHotelGroupsResponse>> => {
  return OAuth().axios.get('/api/admin/groups')
}

export const getHotelParsers = (): Promise<AxiosResponse<IGetHotelParsersResponse>> => {
  return OAuth().axios.get('/api/admin/hotels/parsers')
}
