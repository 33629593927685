import OAuth from '@DevimaSolutions/o-auth'
import { RoomResponseType, RoomsResponseType, RoomType } from '@/redux/rooms/types'
import { BodyChanges } from '@/redux/allocations/types'
import { AxiosResponse } from 'axios'

export const getRoomsWithAttendants = (filter?: string): Promise<RoomsResponseType> => {
  const query = filter ? `?${filter}` : ''
  return OAuth()
    .axios.get(`/api/manager/rooms/workshifts${query}`)
    .then(({ data }: AxiosResponse<RoomResponseType>) => {
      return {
        totalFloors: data.total_count,
        rooms: data.floors?.reduce<Record<string, RoomType>>((acc, floor) => {
          return {
            ...acc,
            ...floor.rooms.reduce<Record<string, RoomType>>((carry, room) => {
              carry[room.id] = {
                ...room,
                floor: floor.floor_number,
              }
              return carry
            }, {}),
          }
        }, {}),
      }
    })
}

export const getAutoAllocationService = (): Promise<Record<string, string[]>[]> => {
  return OAuth()
    .axios.get('/api/manager/allocation/auto-allocate')
    .then(({ data }) => data)
}

export const saveChanges = (body: BodyChanges): Promise<unknown> => {
  return OAuth().axios.put(`/api/manager/allocation`, body)
}
