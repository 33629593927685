import React, { Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route, RouteProps } from 'react-router-dom'
import { FullLayout, Layout } from '@/components/Layouts'
import routes from '@/router/routes'
import LoadingSpinner from '@/components/Spinners/LoadingSpinner'

interface AppRouteProps
  extends Required<Pick<RouteProps, 'component'>>,
    Omit<RouteProps, 'component'> {
  fullLayout?: boolean
  spinner?: boolean
}

const AppRoute: React.FunctionComponent<AppRouteProps> = ({
  component: Component,
  fullLayout,
  spinner,
  ...rest
}: AppRouteProps) => {
  const LayoutTag = fullLayout ? FullLayout : Layout
  return (
    <Route
      {...rest}
      render={(props) => (
        <LayoutTag {...props}>
          <Suspense fallback={<LoadingSpinner />}>
            <Component {...props} />
          </Suspense>
        </LayoutTag>
      )}
    />
  )
}

const AppRouter: React.FunctionComponent = (): JSX.Element => {
  return (
    <Router>
      <Switch>
        {routes.map((route) => (
          <AppRoute key={route.path} {...route} />
        ))}
      </Switch>
    </Router>
  )
}

export default AppRouter
