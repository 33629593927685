import { combineReducers } from 'redux'
import auth, { AuthStateType } from './auth/reducers'
import rooms, { RoomsStateType } from './rooms/reducers'
import room, { RoomStateType } from './room/reducers'
import meeting, { IMeetingState } from './meeting/reducers'
import user, { IUserState } from './user/reducers'
import users, { IUsersState } from './users/reducers'
import shifts, { ShiftStateType } from './shifts/reducers'
import hotels, { IHotelsState } from './hotels/reducers'
import allocations, { AllocationsStateType } from './allocations/reducers'
import notes, { NotesStateType } from './notes/reducers'

export interface IRootState {
  auth: AuthStateType
  rooms: RoomsStateType
  room: RoomStateType
  meeting: IMeetingState
  user: IUserState
  users: IUsersState
  shifts: ShiftStateType
  hotels: IHotelsState
  allocations: AllocationsStateType
  notes: NotesStateType
}

export default combineReducers({
  auth,
  rooms,
  room,
  meeting,
  user,
  users,
  shifts,
  hotels,
  allocations,
  notes,
})
