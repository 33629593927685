import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIconType } from '../types'

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}))

const MeetingBigIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 48, 56',
  active,
  ...rest
}: SvgIconType) => {
  const classes = useStyles()
  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <rect
        opacity="0.3"
        x="5.65039"
        y="8.66113"
        width="36.9112"
        height="41.6158"
        fill="url(#paint0_linear)"
      />
      <path
        d="M12 8.66113L7.65072 8.66113C6.54616 8.66113 5.65073 9.55655 5.65072 10.6611L5.65041 48.3602C5.6504 49.4648 6.54583 50.3602 7.65041 50.3602H40.3532C41.4578 50.3602 42.3532 49.4648 42.3532 48.3602L42.3535 10.6611C42.3535 9.55657 41.4581 8.66113 40.3535 8.66113H36"
        stroke="#53595C"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 30.6572H34" stroke="#53595C" strokeWidth="1.3" strokeLinecap="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.9291 30.6572C30.9758 30.3306 31 29.9967 31 29.6572C31 25.7912 27.866 22.6572 24 22.6572C20.134 22.6572 17 25.7912 17 29.6572C17 29.9967 17.0242 30.3306 17.0709 30.6572H30.9291Z"
        fill="white"
      />
      <path
        d="M30.9291 30.6572V31.3072C31.2525 31.3072 31.5268 31.0694 31.5726 30.7493L30.9291 30.6572ZM17.0709 30.6572L16.4274 30.7493C16.4732 31.0694 16.7475 31.3072 17.0709 31.3072V30.6572ZM30.35 29.6572C30.35 29.9659 30.328 30.269 30.2857 30.5652L31.5726 30.7493C31.6236 30.3923 31.65 30.0276 31.65 29.6572H30.35ZM24 23.3072C27.507 23.3072 30.35 26.1502 30.35 29.6572H31.65C31.65 25.4322 28.225 22.0072 24 22.0072V23.3072ZM17.65 29.6572C17.65 26.1502 20.493 23.3072 24 23.3072V22.0072C19.775 22.0072 16.35 25.4322 16.35 29.6572H17.65ZM17.7143 30.5652C17.672 30.269 17.65 29.9659 17.65 29.6572H16.35C16.35 30.0276 16.3764 30.3923 16.4274 30.7493L17.7143 30.5652ZM17.0709 31.3072H30.9291V30.0072H17.0709V31.3072Z"
        fill="#53595C"
      />
      <path
        d="M24.0059 20.1918V18.6572"
        stroke="#53595C"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.0068 21.0721L28.6483 19.6885"
        stroke="#53595C"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.252 23.5757L32.4343 22.582"
        stroke="#53595C"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.1504 27.2111L34.6221 26.8086"
        stroke="#53595C"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0066 21.0721L19.3525 19.6885"
        stroke="#53595C"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7479 23.5757L15.5781 22.582"
        stroke="#53595C"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.8612 27.2111L13.377 26.8086"
        stroke="#53595C"
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1812 5.66133V6.31133H19.6756L19.8076 5.83496L19.1812 5.66133ZM28.8188 5.66133L28.1924 5.83496L28.3244 6.31133H28.8188V5.66133ZM13 6.31133H19.1812V5.01133H13V6.31133ZM12.65 6.66132C12.65 6.46803 12.8067 6.31133 13 6.31133V5.01133C12.0887 5.01133 11.35 5.75005 11.35 6.66132H12.65ZM12.65 10.3387V6.66132H11.35V10.3387H12.65ZM13 10.6887C12.8067 10.6887 12.65 10.532 12.65 10.3387H11.35C11.35 11.2499 12.0887 11.9887 13 11.9887V10.6887ZM35 10.6887H13V11.9887H35V10.6887ZM35.35 10.3387C35.35 10.532 35.1933 10.6887 35 10.6887V11.9887C35.9113 11.9887 36.65 11.2499 36.65 10.3387H35.35ZM35.35 6.66133V10.3387H36.65V6.66133H35.35ZM35 6.31133C35.1933 6.31133 35.35 6.46803 35.35 6.66133H36.65C36.65 5.75006 35.9113 5.01133 35 5.01133V6.31133ZM28.8188 6.31133H35V5.01133H28.8188V6.31133ZM29.4452 5.48769C28.7839 3.10211 26.5974 1.35 24 1.35V2.65C25.9981 2.65 27.6831 3.99774 28.1924 5.83496L29.4452 5.48769ZM24 1.35C21.4026 1.35 19.2161 3.10211 18.5548 5.48769L19.8076 5.83496C20.3169 3.99774 22.0019 2.65 24 2.65V1.35Z"
        fill="#53595C"
      />
      <path d="M40 24.4541H46V50.4541L43 53.1195L40 50.4541V24.4541Z" fill="white" />
      <path
        d="M46.3321 26.7223V25.4541C46.3321 24.9018 45.8844 24.4541 45.3321 24.4541H40.668C40.1157 24.4541 39.668 24.9018 39.668 25.4541L39.668 50.4541L43 53.4541L46.3321 50.4541V29.4541H43"
        stroke="#53595C"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 37.3721H28"
        stroke="#53595C"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 40.3721H32"
        stroke="#53595C"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 43.3721H23"
        stroke="#53595C"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="21.1122"
          y1="3.84663"
          x2="31.5013"
          y2="50.5938"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.489583" stopColor="#6BB870" stopOpacity="0" />
          <stop offset="1" stopColor="#6BB870" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}

export default MeetingBigIcon
