import { FunctionComponent } from 'react'
import {
  RoomIcon,
  AllocationIcon,
  MeetingIcon,
  UsersIcon,
  SvgIconType,
  HotelsIcon,
} from '@/components/Icons'
import constants from '@/config/constants'

export interface NavigationItemType {
  name: string
  path: string
  icon: FunctionComponent<SvgIconType>
  position: 'top' | 'bottom'
  permission?: string[]
}

const navigations: Array<NavigationItemType> = [
  {
    name: 'rooms',
    path: '/rooms',
    icon: RoomIcon,
    position: 'top',
    permission: [constants.ROLE_MANAGER],
  },
  {
    name: 'allocations',
    path: '/allocations',
    icon: AllocationIcon,
    position: 'top',
    permission: [constants.ROLE_MANAGER],
  },
  {
    name: 'morning-meeting',
    path: '/morning-meeting',
    icon: MeetingIcon,
    position: 'bottom',
    permission: [constants.ROLE_MANAGER],
  },
  {
    name: 'hotels',
    path: '/admin/hotels',
    icon: HotelsIcon,
    position: 'top',
    permission: [constants.ROLE_ADMIN],
  },
  {
    name: 'users',
    path: '/admin/users',
    icon: UsersIcon,
    position: 'top',
    permission: [constants.ROLE_ADMIN],
  },
]
export default navigations
