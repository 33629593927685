import OAuth, { IAuth } from '@DevimaSolutions/o-auth'
import { LoginFormType, ResetPassword } from '@/redux/auth/actions'

export const login = (data: LoginFormType): Promise<void | IAuth> => {
  return OAuth().signIn(data.email, data.password)
}

export const logout = (): Promise<void | IAuth> => {
  return OAuth().signOut()
}

export const forgotPassword = (email: string): Promise<void> =>
  OAuth().axios.post(`/api/auth/forgot`, { email, type: 'web' })

export const resetPassword = ({ password, otp }: ResetPassword): Promise<void> =>
  OAuth().axios.post(`/api/auth/forgot-verify`, { password, otp })
