import qs from 'qs'
import { AnyAction } from 'redux'
import { takeLatest, put, call, select, take, race, spawn } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { updateCsv } from '@/redux/hotels/actions'
import {
  fetchRoomsList,
  uploadFile as upload,
  fetchFile,
  changeRoomStatus as changeRoomStatusService,
  changeFlagForCsv,
} from '@/services/rooms'
import messages from '@/config/messages'
import { IRootState } from '@/redux/reducers'
import { SagaIterator } from '@redux-saga/types'
import { changeActiveRoomStatus } from '@/redux/room/actions'
import { setUserInfo } from '@/redux/user/actions'
import { IS_DEV } from '@/config/constants'
import actions, { loading, uploading, saveRoomsList, getRoomsList, updateRoomById } from './actions'
import { convertRoomsInFloorToObj } from './service'
import eventChannel from './websocket'

export function* getRoomList({ payload }: AnyAction): SagaIterator {
  yield put(loading(true))

  try {
    const query = qs.stringify(payload.filter, { skipNulls: true })
    const { data } = yield call(fetchRoomsList, query)

    yield put(
      saveRoomsList({
        floors: convertRoomsInFloorToObj(data.floors),
        totalFloors: data.total_count,
      }),
    )
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

export function* uploadFile({ payload }: AnyAction): SagaIterator {
  yield put(uploading(true))

  const fd = new FormData()
  fd.append('file', payload.file)

  try {
    const { data } = yield call(upload, fd)

    yield put(updateCsv(data.date))

    if (payload.onUploadSuccess) {
      payload.onUploadSuccess()
    }
    const rooms = yield select((state: IRootState) => state.rooms)
    yield put(getRoomsList(rooms.filter))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(uploading(false))
  }
}

export function* exportFile({ payload }: AnyAction): SagaIterator {
  yield put(uploading(true))

  try {
    const resp = yield call(fetchFile)
    const fileName = resp.headers?.['x-file-name'] || 'download.csv'
    if (payload.onSuccess) {
      payload.onSuccess(resp.data, fileName)
    }
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(uploading(false))
  }
}

export function* changeRoomStatus({ payload }: AnyAction): SagaIterator {
  yield put(loading(true))
  try {
    yield call(changeRoomStatusService, payload)
    yield put(changeActiveRoomStatus(payload.status))
    yield put(updateRoomById(payload))
    toast.success('Room status was changed!')
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

export function* closeCsvModal({ data }: AnyAction): SagaIterator {
  try {
    yield call(changeFlagForCsv)
    yield put(setUserInfo(data))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  }
}

function* listenRoomsSaga({ id }: AnyAction): SagaIterator {
  const roomChannel = yield call(eventChannel, id)
  try {
    while (true) {
      const action = yield take(roomChannel)
      yield put(action)
    }
  } catch (e) {
    if (IS_DEV) {
      // eslint-disable-next-line no-console
      console.log('listenRoomsSagaError', {
        message: (e as Error).message,
      })
    }
    toast.error(messages.socketError)
  } finally {
    roomChannel?.close?.()
  }
}

function* websocketChannel(): SagaIterator {
  while (true) {
    const action = yield take(actions.START_ROOMS_SOCKET_CHANNEL)
    try {
      yield race({
        task: call(listenRoomsSaga, action),
        cancelTask: take(actions.CLOSE_ROOMS_SOCKET_CHANNEL),
      })
    } catch (e) {
      if (IS_DEV) {
        // eslint-disable-next-line no-console
        console.log('watchRoomsSagaError', {
          message: (e as Error).message,
        })
      }
      toast.error(messages.socketError)
    }
  }
}

export default function* rootSaga(): SagaIterator {
  yield spawn(websocketChannel)
  yield takeLatest(actions.GET_ROOMS_LIST, getRoomList)
  yield takeLatest(actions.UPLOAD_CSV_FILE, uploadFile)
  yield takeLatest(actions.EXPORT_FILE, exportFile)
  yield takeLatest(actions.CHANGE_ROOM_STATUS, changeRoomStatus)
  yield takeLatest(actions.CLOSE_CSV_MODAL, closeCsvModal)
}
