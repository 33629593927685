import OAuth from '@DevimaSolutions/o-auth'
import { RoomResponseType, ChangeStatusType } from '@/redux/rooms/types'
import { AxiosResponse } from 'axios'

export const fetchRoomsList = (filter?: string): Promise<AxiosResponse<RoomResponseType>> => {
  const query = filter ? `?${filter}` : ''
  return OAuth().axios.get(`/api/manager/rooms${query}`)
}

export const uploadFile = (fd: FormData, onUploadProgress?: () => void): Promise<unknown> => {
  return OAuth().axios.post(`/api/manager/set-day-schedule`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress,
  })
}

export const fetchFile = (): Promise<AxiosResponse<Blob>> => {
  return OAuth().axios.get(`/api/manager/export-schedule`, {
    responseType: 'blob',
  })
}

export const fetchOne = (id: string): Promise<AxiosResponse<Blob>> => {
  return OAuth().axios.get(`/api/room/${id}`)
}

export const getRoomPinnedNotes = (id: string): Promise<AxiosResponse<Blob>> => {
  return OAuth().axios.get(`/api/room/${id}/pinned-note`)
}

export const changeRoomStatus = ({
  id,
  status,
}: ChangeStatusType): Promise<AxiosResponse<unknown>> => {
  return OAuth().axios.put(`/api/manager/rooms/${id}`, {
    status,
  })
}

export const setPreviousStatus = (id: string): Promise<AxiosResponse<unknown>> =>
  OAuth().axios.post(`/api/manager/rooms/set-previous-status/${id}`)

export const changeFlagForCsv = (): Promise<AxiosResponse<unknown>> =>
  OAuth().axios.post(`/api/manager/check-csv`)
