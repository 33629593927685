import type H from 'history'

export interface IRedirect {
  history: H.History
  redirectType: string
}

function redirectToLogin(payload: IRedirect): void {
  switch (payload.redirectType) {
    case 'attendant': {
      window.location.href = 'greenparcstaff://login'
      break
    }
    case 'manager': {
      window.location.href = 'greenparcmanager://login'
      break
    }
    default: {
      payload.history.push('/login')
    }
  }
}

export default redirectToLogin
