import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIconType } from '../types'

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}))

const PictureIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 28, 28',
  ...rest
}: SvgIconType) => {
  const classes = useStyles()
  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <path
        d="M2.1543 5.23077C2.1543 4.12621 3.04973 3.23077 4.1543 3.23077H23.8466C24.9512 3.23077 25.8466 4.1262 25.8466 5.23077V22.7692C25.8466 23.8738 24.9512 24.7692 23.8466 24.7692H4.1543C3.04973 24.7692 2.1543 23.8738 2.1543 22.7692V5.23077Z"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="10.2304" cy="10.2308" r="2.69231" stroke="#1B1C1D" strokeWidth="1.3" />
      <path
        d="M9.69238 20.4615C12.4261 16.3077 14.2486 20 16.0711 20C18.8048 20 17.8936 14 21.5385 14"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default PictureIcon
