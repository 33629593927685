import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useAuth from '@/context/AuthContext'
import { Redirect } from 'react-router-dom'
import LoadingSpinner from '@/components/Spinners/LoadingSpinner'
import Navigation from './components/Navigation'
import { LayoutProps } from '../types'

const useStyles = makeStyles(() => ({
  layout: {
    display: 'flex',
    minHeight: '100vh',
  },
  page: {
    display: 'flex',
    flexGrow: 1,
  },
}))

const Layout: React.FunctionComponent<LayoutProps> = ({ children, match }: LayoutProps) => {
  const classes = useStyles()
  const { authenticated, isPending, user } = useAuth()

  if (!authenticated && !isPending) {
    return <Redirect to="/login" />
  }

  if (!user) {
    return <LoadingSpinner />
  }

  return (
    <div className={classes.layout}>
      <Navigation user={user} activePath={match.path} />
      <div className={classes.page}>{children}</div>
    </div>
  )
}

export default Layout
