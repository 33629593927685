import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { SvgIconType } from '../types'

const CheckBoxIcon: React.FunctionComponent<SvgIconType> = (props: SvgIconType) => {
  return (
    <SvgIcon {...props}>
      <rect
        xmlns="http://www.w3.org/2000/svg"
        x="0.5"
        y="0.5"
        width="25"
        height="25"
        rx="3.5"
        fill="white"
        stroke="#DBDDDF"
      />
    </SvgIcon>
  )
}

export default CheckBoxIcon
