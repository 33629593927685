const localStore = {
  get: <T, D = null>(key: string, optionalDefaultValue?: D): T | D | null => {
    const data = localStorage.getItem(key)
    return localStore.deserialize<T, D>(data, optionalDefaultValue)
  },
  set: <T>(key: string, value: T): T => {
    localStorage.setItem(key, localStorage.serialize(value))
    return value
  },
  remove: (key: string): void => {
    localStorage.removeItem(key)
  },
  serialize: <T>(obj: T): string => {
    return JSON.stringify(obj)
  },
  deserialize: <T, D>(strVal: string | null, defaultVal?: D): T | D | null => {
    if (!strVal) {
      return defaultVal || null
    }

    try {
      const val = JSON.parse(strVal)
      return val !== undefined ? val : defaultVal || null
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
    }

    return null
  },
}

export default localStore
