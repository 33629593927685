import { Socket } from 'socket.io-client'
import oAuth from '@DevimaSolutions/o-auth'
import { ISocketClientOptions } from '@DevimaSolutions/o-auth/src/socketManager/socketManager.types'

export const socketDefaultConfig = {
  agent: false,
  timeout: 60000,
  reconnection: true,
  transports: ['websocket'],
  // upgrade: false,
  jsonp: false,
  forceNew: true,
}

export const createSocketConnection = (
  socketRoom: string,
  id?: string,
  params: ISocketClientOptions = socketDefaultConfig,
): Promise<Socket> =>
  oAuth().socketManager.createSocketConnection(
    process.env.REACT_APP_WEB_SOCKET_API_URL ?? '',
    socketRoom,
    id,
    params,
  )
