import OAuth from '@DevimaSolutions/o-auth'
import { IMessageResponse } from '@/redux/meeting/types'

export const getMorningMessage = (id: string): Promise<IMessageResponse> =>
  OAuth().axios.get(`/api/user/morning-meeting/${id}`)

export const createMorningMessage = (text: string): Promise<IMessageResponse> =>
  OAuth().axios.post('/api/manager/morning-meeting', { text })

export const updateMorningMessage = (id: string, text: string): Promise<IMessageResponse> =>
  OAuth().axios.put(`/api/manager/morning-meeting/${id}`, { text })
