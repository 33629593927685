import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIconType } from '../types'

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}))

const ArrowNextIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 21, 41',
  ...rest
}: SvgIconType) => {
  const classes = useStyles()
  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <path
        d="M1 1L20 20L1 39"
        stroke={rest.stroke ?? 'white'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default ArrowNextIcon
