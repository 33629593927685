import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { SvgIconType } from '../types'

const CheckedCheckBoxIcon: React.FunctionComponent<SvgIconType> = (props: SvgIconType) => {
  return (
    <SvgIcon {...props}>
      <rect x="0.5" y="0.5" width="25" height="25" rx="3.5" fill="white" stroke="#DBDDDF" />
      <path
        d="M8 14L11 17L18 10"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default CheckedCheckBoxIcon
