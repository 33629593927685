import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIconType } from '../types'

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}))

const UsersIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 34, 34',
  active,
  ...rest
}: SvgIconType) => {
  const classes = useStyles()
  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <path
        d="M18.8648 22.9259L15.2345 21.6999C12.9107 23.9596 9.9332 21.1212 7.34528 22.6115C11.3361 24.8064 15.6927 27.1352 19.8549 29.3959L20.524 25.7451C20.7479 24.5237 20.0413 23.3232 18.8648 22.9259Z"
        fill={active ? 'url(#paint0_linear)' : 'none'}
      />
      <path
        d="M18.1997 14.7357C18.1997 16.2689 17.6099 17.7393 16.56 18.8235C15.5102 19.9076 14.0862 20.5167 12.6015 20.5167C11.1167 20.5167 9.69276 19.9076 8.64287 18.8235C7.59299 17.7393 7.00317 16.2689 7.00317 14.7357C9.24249 14.7357 12.6015 13.4524 13.7211 11.1862C15.203 13.6203 18.1997 11.5446 18.1997 14.7357Z"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
      <path
        d="M12.6015 7.9198C9.70155 7.9198 7.00317 10.2372 7.00317 14.9177"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6016 7.9198C15.5015 7.9198 18.1999 10.2372 18.1999 14.9177"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0657 28.9136L20.5845 25.6735C20.7752 24.4823 20.0881 23.3249 18.9511 22.9219L15.1456 21.5731V19.9283"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.3875 28.9136L29.1387 26.077C29.517 24.6484 28.5802 23.2052 27.1213 22.9692L23.4674 22.3779V19.9283"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2438 22.6011V19.9283"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.00198 28.9136L4.4832 25.6735C4.29248 24.4823 4.97957 23.3249 6.11661 22.9219L9.92206 21.5731V19.9283"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.80286 8.3867V8.04324C9.80286 7.26822 10.0978 6.52495 10.6227 5.97693C11.1476 5.42891 11.8596 5.12103 12.602 5.12103C13.3444 5.12103 14.0563 5.42891 14.5813 5.97693C15.1062 6.52495 15.4011 7.26822 15.4011 8.04324V8.3867"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2809 21.6599C14.9328 22.3182 13.8649 22.7975 12.6015 22.7975C11.3381 22.7975 10.2702 22.3182 9.92206 21.6599"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0614 18.3132C18.0501 19.6634 19.5053 20.5167 21.1278 20.5167C24.1063 20.5167 26.5209 17.6414 26.5209 14.0945C26.5209 10.5476 25.0874 7.6723 21.1278 7.6723C18.9978 7.6723 17.5988 8.50435 16.7607 9.8254"
        stroke="#1B1C1D"
        strokeWidth="1.3"
      />
      <path
        d="M25.629 10.0611C23.0296 14.499 19.5631 9.50982 16.8052 10.0612"
        stroke="#1B1C1D"
        strokeWidth="1.3"
      />
      <path
        d="M23.5422 22.3222L21.7144 23.1082C21.2241 23.3191 20.6699 23.3252 20.175 23.1253L18.1874 22.3222"
        stroke="#1B1C1D"
        strokeWidth="1.3"
      />

      {active && (
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="17.8549"
            y1="22.6507"
            x2="15.5804"
            y2="26.8076"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6BB870" />
            <stop offset="1" stopColor="#6BB870" stopOpacity="0" />
          </linearGradient>
        </defs>
      )}
    </SvgIcon>
  )
}

export default UsersIcon
