import axios from 'axios'
import { IAuthOptions } from '@DevimaSolutions/o-auth'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
})

const oAuthOptions: IAuthOptions = {
  axiosInstance,
  signIn: async (email: string, password: string) =>
    axiosInstance.post('/api/auth/login', {
      grand_type: 'password',
      email,
      password,
      scope: 'web_manager',
    }),
  signOut: async (authToken: string): Promise<void> =>
    // eslint-disable-next-line no-void
    void axiosInstance
      .post(
        '/api/auth/logout',
        {},
        {
          headers: {
            authorization: authToken,
          },
        },
      )
      .catch(() => {}),
  refreshToken: async (refresh_token: string) =>
    axiosInstance.post('/api/auth/login', {
      grand_type: 'refresh',
      refresh_token,
    }),
  getUser: async (authToken: string) =>
    axiosInstance.get('/api/user', {
      headers: {
        authorization: authToken,
      },
    }),
}

export default oAuthOptions
