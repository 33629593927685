import React, { useState } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIconType } from '../types'

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}))

const AllocationIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 34, 34',
  active,
  ...rest
}: SvgIconType) => {
  const [hover, setHover] = useState(false)
  const strokeColor = hover ? '#1B1C1D' : '#53595C'
  const classes = useStyles()
  return (
    <SvgIcon
      {...rest}
      classes={classes}
      viewBox={viewBox}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      {active && (
        <defs>
          <linearGradient
            id="paint0_linear_allocation"
            x1="23.4225"
            y1="21.9205"
            x2="20.2016"
            y2="27.8254"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6BB870" />
            <stop offset="1" stopColor="#6BB870" stopOpacity="0" />
          </linearGradient>
        </defs>
      )}
      <path
        d="M2.65454 27.4237H11.718"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.34277 23.6315L12.135 27.4237L8.34277 31.2159"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5697 29.9727L26.0516 26.1834C26.3341 24.865 25.5946 23.5416 24.3237 23.0911C24.3237 23.0911 22.1282 22.2226 20.1382 21.5262C18.0931 22.9886 15.9653 22.9278 13.804 21.5262L12.1494 21.9205L11.9663 22.6252L15.9653 28.3789H22.1054L22.2878 29.7974L25.5697 29.9727Z"
        fill="url(#paint0_linear_allocation)"
      />
      <path
        d="M23.4225 13.6581C23.4225 15.4223 22.7439 17.1143 21.5358 18.3618C20.3277 19.6093 18.6893 20.3101 16.9808 20.3101C15.2723 20.3101 13.6339 19.6093 12.4258 18.3618C11.2177 17.1143 10.5391 15.4223 10.5391 13.6581C13.1158 13.6581 16.9808 12.1814 18.2691 9.57385C19.9743 12.3746 23.4225 9.98627 23.4225 13.6581Z"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
      <path
        d="M16.9808 5.81616C13.644 5.81616 10.5391 8.48273 10.5391 13.8683"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9807 5.81616C20.3175 5.81616 23.4225 8.48273 23.4225 13.8683"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5698 29.9727L26.2163 25.9346C26.4071 24.7435 25.72 23.586 24.5829 23.183L19.9084 21.5263V19.6337"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0667 19.6337V21.5263L12.5213 22.1166"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.349 22.4574V28.3623M22.349 29.9728V28.3623M22.349 28.3623H15.9072"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.76 6.35302V5.95781C13.76 5.06603 14.0994 4.21077 14.7034 3.58018C15.3074 2.9496 16.1267 2.59534 16.9809 2.59534C17.8351 2.59534 18.6543 2.9496 19.2584 3.58018C19.8624 4.21077 20.2017 5.06603 20.2017 5.95781V6.35302"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0639 21.626C19.6633 22.3836 18.4346 22.9351 16.9808 22.9351C15.5271 22.9351 14.2983 22.3836 13.8977 21.626"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default AllocationIcon
