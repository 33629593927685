import { AnyAction } from 'redux'
import { ShiftType } from '@/redux/shifts/types'

const actions = {
  SET_STATE: 'shifts/SET_STATE',
  GET_SHIFT_LIST: 'shifts/GET_SHIFT_LIST',
  REFRESH_SHIFT_LIST: 'shifts/REFRESH_SHIFT_LIST',
}

export const loading = (data: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: { loading: data },
  }
}

export const setState = (payload: unknown): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload,
  }
}

export const saveShiftsList = (shifts: ShiftType[]): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: { shifts },
  }
}

export const getShiftsList = (): AnyAction => {
  return {
    type: actions.GET_SHIFT_LIST,
  }
}

export const refreshShiftsList = (): AnyAction => {
  return {
    type: actions.REFRESH_SHIFT_LIST,
  }
}

export default actions
