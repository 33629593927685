import {
  IGetUsersRequest,
  IGetUsersResponse,
  IResetUserPasswordRequest,
  IUpdateUserRequest,
} from '@/redux/users/actions'
import OAuth from '@DevimaSolutions/o-auth'

export const getUsersList = ({ page, limit }: IGetUsersRequest): Promise<IGetUsersResponse> =>
  OAuth().axios.get(`/api/admin/users?page=${page + 1}&limit=${limit}`)

export const updateUser = ({ id, values }: IUpdateUserRequest): unknown =>
  OAuth().axios.put(`/api/admin/users/${id}`, values)

export const resetUserPassword = ({ password, id }: IResetUserPasswordRequest): unknown =>
  OAuth().axios.put(`/api/admin/users/reset-password/${id}`, {
    password,
  })

export const deleteUser = (id: string): unknown => OAuth().axios.delete(`/api/admin/users/${id}`)

export const getActiveUser = (id: string): unknown => OAuth().axios.get(`/api/admin/users/${id}`)

export const addNewUser = (data: FormData): unknown => OAuth().axios.post(`/api/admin/users/`, data)
