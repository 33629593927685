import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIconType } from '../types'

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}))

const UsersIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 34, 34',
  active,
  ...rest
}: SvgIconType) => {
  const classes = useStyles()
  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 27H8V17H15H19H26V27H21V23C21 22.4477 20.5523 22 20 22H14C13.4477 22 13 22.4477 13 23V27Z"
        fill={active ? 'url(#paint0_linear)' : 'none'}
      />
      <path
        d="M26.3491 27.2453L26.3942 7.64874C26.3954 7.09555 25.9474 6.64643 25.3942 6.64643H22.7741L22.742 4.63626C22.7333 4.09027 22.2882 3.65222 21.7421 3.65222H12.2829C11.7243 3.65222 11.2741 4.10973 11.283 4.66818L11.3146 6.64643H8.69588C8.14449 6.64643 7.69715 7.09274 7.69588 7.64413L7.65076 27.2453"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 22.2454H22" stroke="#1B1C1D" strokeWidth="1.3" strokeLinecap="round" />
      <path
        d="M5 27.3434H29"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M13 22.2454V27.2454" stroke="#1B1C1D" strokeWidth="1.3" strokeLinecap="round" />
      <path d="M21 22.2454V27.2454" stroke="#1B1C1D" strokeWidth="1.3" strokeLinecap="round" />
      <mask id="path-7-inside-1" fill="white">
        <rect x="14" y="10" width="6" height="8" rx="1" />
      </mask>
      <rect
        x="14"
        y="10"
        width="6"
        height="8"
        rx="1"
        stroke="#1B1C1D"
        strokeWidth="2.6"
        mask="url(#path-7-inside-1)"
      />

      {active && (
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="17"
            y1="27"
            x2="17"
            y2="17"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6BB870" />
            <stop offset="1" stopColor="#6BB870" stopOpacity="0" />
          </linearGradient>
        </defs>
      )}
    </SvgIcon>
  )
}

export default UsersIcon
