import { createMorningMessage, getMorningMessage, updateMorningMessage } from '@/services/meeting'
import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { toast } from 'react-toastify'
import messages from '@/config/messages'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import actions, { getMessageSuccess, loading, sendMessageSuccess } from './actions'
import { IMessageResponse } from './types'
import { getActiveMorningMessageId } from './selectors'

function* getMessage(action: AnyAction) {
  yield put(loading(true))
  try {
    const { data } = yield call(getMorningMessage, action.data)

    yield put(getMessageSuccess(data))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

function* sendMessage(action: AnyAction) {
  yield put(loading(true))
  try {
    const id: string | undefined = yield select(getActiveMorningMessageId)
    let result: { data: IMessageResponse }
    if (id) {
      result = yield call(updateMorningMessage, id, action.data)
    } else {
      result = yield call(createMorningMessage, action.data)
    }
    yield put(sendMessageSuccess(result.data))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

export default function* rootSaga(): SagaIterator {
  yield all([
    takeEvery(actions.GET_MESSAGE_REQUEST, getMessage),
    takeEvery(actions.SEND_MESSAGE_REQUEST, sendMessage),
  ])
}
