import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import filter from 'lodash/filter'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Logo from '@/assets/img/logo-short.png'
import RightPanelLine from '@/assets/svg/right-panel-line.svg'
import navigations, { NavigationItemType } from '@/config/navigations'
import { UserType } from '@/redux/user/types'

const useStyles = makeStyles((theme: Theme) => ({
  nav: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRight: '1px solid #DBDDDF',
  },
  topNav: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomNav: {
    display: 'flex',
    flexDirection: 'column',
  },
  navItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(2.25, 0),
    position: 'relative',
  },
  navItemLink: {
    cursor: 'pointer',
  },
  logo: {
    '& > img': {
      width: 16,
      height: 27,
    },
  },
  icon: {
    fontSize: 34,
    margin: '0px 18px',
  },
  grow: {
    flexGrow: 1,
  },
  avatar: {
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
  },
  rightLine: {
    position: 'absolute',
    right: 0,
  },
}))

interface NavigationProps {
  user: UserType | null
  activePath: string
}

const Navigation: React.FunctionComponent<NavigationProps> = ({
  user,
  activePath,
}: NavigationProps) => {
  const classes = useStyles()
  const history = useHistory()
  const topNavItems = filter(navigations, { position: 'top' })
  const bottomNavItems = filter(navigations, { position: 'bottom' })

  const avatar = useMemo(() => user?.image ?? '', [user?.image])

  const renderNavItem = (items: NavigationItemType[]) =>
    items.map(({ name, path, icon: Icon, permission }) => {
      if (activePath.startsWith(path) && !permission?.includes(user?.role ?? '')) {
        return history.push('/')
      }
      if (permission?.includes(user?.role ?? '')) {
        const isActive = activePath.startsWith(path)
        return (
          <div
            key={name}
            onClick={() => history.push(path)}
            aria-hidden="true"
            className={clsx(classes.navItem, classes.navItemLink)}
          >
            <Icon className={classes.icon} active={isActive} />
            {isActive && <img src={RightPanelLine} className={classes.rightLine} alt="*" />}
          </div>
        )
      }
      return null
    })

  return (
    <div className={classes.nav}>
      <div className={classes.topNav}>
        <div className={classes.navItem}>
          <div className={classes.logo}>
            <img src={Logo} alt="greenparc logo" />
          </div>
        </div>
        {renderNavItem(topNavItems)}
      </div>
      <div className={classes.bottomNav}>
        {renderNavItem(bottomNavItems)}
        <div className={clsx(classes.navItem, classes.navItemLink)}>
          <Avatar
            // alt={user?.name}
            src={avatar}
            onClick={() => history.push('/account')}
            className={classes.avatar}
          />
        </div>
      </div>
    </div>
  )
}

export default Navigation
