import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIconType } from '../types'

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}))

const AddressIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 18, 18',
  ...rest
}: SvgIconType) => {
  const classes = useStyles()
  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <path
        d="M15.3649 15.9999V3.65918C15.3649 3.10689 14.9172 2.65918 14.3649 2.65918H13.1412C12.9824 2.65918 12.826 2.6214 12.6848 2.54897L9.91297 1.12732C9.33997 0.833427 8.6605 0.833427 8.0875 1.12732L5.31568 2.54897C5.17446 2.6214 5.01802 2.65918 4.85931 2.65918H3.63965C3.08736 2.65918 2.63965 3.10689 2.63965 3.65918V15.9999"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 16.3506H17"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="5" y="6.01343" width="2" height="2" rx="0.5" fill="#1B1C1D" />
      <rect x="8" y="6.01343" width="2" height="2" rx="0.5" fill="#1B1C1D" />
      <rect x="11" y="6.01343" width="2" height="2" rx="0.5" fill="#1B1C1D" />
      <rect x="5" y="10" width="2" height="2" rx="0.5" fill="#1B1C1D" />
      <rect x="8" y="10" width="2" height="2" rx="0.5" fill="#1B1C1D" />
      <rect x="11" y="10" width="2" height="2" rx="0.5" fill="#1B1C1D" />
    </SvgIcon>
  )
}

export default AddressIcon
