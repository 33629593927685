import { AnyAction } from 'redux'
import { IMessageResponse } from './types'

const actions: { [key: string]: string } = {
  SET_LOADING: 'meeting/SET_LOADING',
  GET_MESSAGE_REQUEST: 'meeting/GET_MESSAGE_REQUEST',
  GET_MESSAGE_SUCCESS: 'meeting/GET_MESSAGE_SUCCESS',
  GET_MESSAGE_ERROR: 'meeting/GET_MESSAGE_ERROR',

  SEND_MESSAGE_REQUEST: 'meeting/SEND_MESSAGE_REQUEST',
  SEND_MESSAGE_SUCCESS: 'meeting/SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_ERROR: 'meeting/SEND_MESSAGE_ERROR',
}

export const loading = (data: boolean): AnyAction => ({
  type: actions.SET_LOADING,
  data,
})

export const getMessage = (data: string): AnyAction => ({
  type: actions.GET_MESSAGE_REQUEST,
  data,
})

export const getMessageSuccess = (data: IMessageResponse): AnyAction => ({
  type: actions.GET_MESSAGE_SUCCESS,
  data,
})

export const sendMessageRequest = (data: string): AnyAction => ({
  type: actions.SEND_MESSAGE_REQUEST,
  data,
})

export const sendMessageSuccess = (data: IMessageResponse): AnyAction => ({
  type: actions.SEND_MESSAGE_SUCCESS,
  data,
})

export default actions
