import { isToday, isTomorrow, isYesterday, isThisWeek, format } from 'date-fns'

export const getDate = (date: Date | string | number): Date => {
  if (date instanceof Date) {
    return date
  }

  if (['string', 'number'].includes(typeof date)) {
    return new Date(date)
  }

  return new Date()
}

export const formatDate = (date: Date | string | number, onlyDay = false): string => {
  const currentDate = getDate(date)
  if (isTomorrow(currentDate)) {
    return onlyDay ? `Tomorrow` : `Tomorrow, ${format(currentDate, 'hh:mm aaa')}`
  }

  if (isToday(currentDate)) {
    return onlyDay ? `Today` : `Today, ${format(currentDate, 'hh:mm aaa')}`
  }

  if (isYesterday(currentDate)) {
    return onlyDay ? `Yesterday` : `Yesterday, ${format(currentDate, 'hh:mm aaa')}`
  }

  if (isThisWeek(currentDate)) {
    return `${format(currentDate, onlyDay ? 'EEE' : 'EEE, hh:mm aaa')}`
  }

  return format(currentDate, onlyDay ? 'LLL d' : 'LLL d, hh:mm aaa')
}

export const formatToDayAndMonth = (date: string): string => `${format(getDate(date), 'MMM d')}`

export const formatToHour = (date: string): string => `${format(new Date(date), "hh:mm aaaaa'm'")}`

export const formatShiftTime = (start: string, end: string): string =>
  `${format(getDate(start), 'h:mm aaa')} - ${format(getDate(end), 'h:mm aaa')}`
