import React, { useEffect, useMemo, useState } from 'react'
import OAuth, { IAuth } from '@DevimaSolutions/o-auth'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '@/redux/reducers'
import { setUserInfo } from '@/redux/user/actions'
import { UserType } from '@/redux/user/types'
import store from '@/utils/store'

export interface ContextProps {
  authenticated: boolean
  isPending: boolean
  initialPending: boolean
  user: UserType | null
  role: string | null
}

interface ProviderProps {
  children: React.ReactNode
}

const AuthContext = React.createContext<Partial<ContextProps>>({})

export const AuthProvider: React.FunctionComponent<ProviderProps> = ({
  children,
}: ProviderProps) => {
  const [authenticated, setAuthenticated] = useState(false)
  const [isPending, setPending] = useState(true)
  const [initialPending, setInitialPending] = useState(true)
  const user = useSelector((state: IRootState) => state.user.user)
  const role = useMemo(() => user?.role ?? null, [user])
  const dispatch = useDispatch()

  useEffect(() => {
    const unsubscribeAuthStateChanged = OAuth().onAuthStateChanged((auth: IAuth) => {
      setAuthenticated(auth.isSignedIn())
      setPending(auth.isPending())
      const result = store.get<UserType>('@o-auth/user')
      dispatch(setUserInfo(result))
    })

    const unsubscribePendingStateChanged = OAuth().onPendingStateChanged((auth: IAuth) => {
      setPending(auth.isPending())
    })

    const unsubscribePendingActionComplete = OAuth().oncePendingActionComplete(() => {
      setInitialPending(false)
    })

    // const unsubscribeUserChanged = OAuth().onUserChanged(async (auth) => {
    //   const result = await auth.getUser()
    //   setUser(result)
    // })
    return () => {
      unsubscribeAuthStateChanged()
      unsubscribePendingStateChanged()
      unsubscribePendingActionComplete()
      // unsubscribeUserChanged()
    }
  }, [dispatch])

  return (
    <AuthContext.Provider
      value={{
        authenticated,
        isPending,
        initialPending,
        user,
        role,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = (): Partial<ContextProps> => {
  const context = React.useContext(AuthContext)

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }

  return context
}

export default useAuth
