import { lazy } from 'react'

const home = lazy(() => import('@/views/Home'))
const login = lazy(() => import('@/views/Login'))
const forgotPassword = lazy(() => import('@/views/ForgotPassword'))
const passwordReset = lazy(() => import('@/views/PasswordReset'))
const account = lazy(() => import('@/views/Account'))
const meeting = lazy(() => import('@/views/MorningMeeting'))
const allocations = lazy(() => import('@/views/Allocations'))
const rooms = lazy(() => import('@/views/Rooms'))
const adminUsers = lazy(() => import('@/views/Users'))
const userDetails = lazy(() => import('@/views/UserDetails'))
const addUser = lazy(() => import('@/views/AddUser'))
const adminHotels = lazy(() => import('@/views/Hotels'))
const addHotel = lazy(() => import('@/views/AddHotel'))
const editHotel = lazy(() => import('@/views/EditHotel'))

const routes = [
  {
    component: home,
    path: '/',
    exact: true,
  },
  {
    component: login,
    path: '/login',
    exact: true,
    fullLayout: true,
  },
  {
    component: passwordReset,
    path: '/password-reset/:otp/:type',
    exact: true,
    fullLayout: true,
  },
  {
    component: forgotPassword,
    path: '/forgot-password',
    exact: true,
    fullLayout: true,
  },
  {
    component: account,
    path: '/account',
    exact: true,
  },
  {
    component: rooms,
    path: '/rooms',
    exact: true,
  },
  {
    component: meeting,
    path: '/morning-meeting',
    exact: true,
  },
  {
    component: allocations,
    path: '/allocations',
    exact: true,
  },
  {
    component: adminUsers,
    path: '/admin/users',
    exact: true,
  },
  {
    component: addUser,
    path: '/admin/users/add',
    exact: true,
  },
  {
    component: userDetails,
    path: '/admin/users/:id',
    exact: true,
  },
  {
    component: adminHotels,
    path: '/admin/hotels',
    exact: true,
  },
  {
    component: addHotel,
    path: '/admin/hotels/add',
    exact: true,
  },
  {
    component: editHotel,
    path: '/admin/hotels/:id',
    exact: true,
  },
]

export default routes
