import { AnyAction } from 'redux'
import { SagaIterator } from 'redux-saga'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import messages from '@/config/messages'
import {
  getUsersList,
  updateUser as updateUserService,
  deleteUser as deleteUserService,
  getActiveUser as getActiveUserService,
  resetUserPassword as resetUserPasswordService,
  addNewUser as addNewUserService,
} from '@/services/users'
import actions, { getUsersSuccess, loading, setActiveUser, setError } from './actions'

function* getUsers(action: AnyAction) {
  yield put(loading(true))
  try {
    const { data } = yield call(getUsersList, action.data)
    yield put(getUsersSuccess(data))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

function* updateUser(action: AnyAction) {
  yield put(loading(true))
  try {
    const { data } = yield call(updateUserService, action.data)
    yield put(setActiveUser(data))
    toast.success('User was updated!')
  } catch (e) {
    if (e?.response?.status === 400) {
      if (e?.response?.data?.error && typeof e?.response?.data?.error === 'object') {
        yield put(setError(e?.response?.data?.error))
        toast.error(messages.formHasErrors)
      } else {
        yield put(setError({}))
        toast.error(e?.response?.data?.error || messages.formHasErrors)
      }
    } else {
      toast.error(e?.response?.data?.error || messages.responseError)
    }
  } finally {
    yield put(loading(false))
  }
}

function* deleteUser(action: AnyAction) {
  yield put(loading(true))
  try {
    const { id, history } = action.data
    yield call(deleteUserService, id)
    toast.success('User was deleted!')
    history.push('/admin/users')
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

function* getActiveUser(action: AnyAction) {
  yield put(loading(true))
  try {
    const { data } = yield call(getActiveUserService, action.id)
    yield put(setActiveUser(data))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

function* resetUserPassword(action: AnyAction) {
  yield put(loading(true))
  try {
    yield call(resetUserPasswordService, action.data)
    toast.success('Password was reset!')
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

function* addNewUser(action: AnyAction) {
  yield put(loading(true))
  const { data, history } = action.data
  try {
    yield call(addNewUserService, data)
    toast.success('User was created!')
    yield put(setError({}))
    history.goBack()
  } catch (e) {
    if (e?.response?.status === 400) {
      if (e?.response?.data?.error && typeof e?.response?.data?.error === 'object') {
        yield put(setError(e?.response?.data?.error))
        toast.error(messages.formHasErrors)
      } else {
        yield put(setError({}))
        toast.error(e?.response?.data?.error || messages.formHasErrors)
      }
    } else {
      toast.error(e?.response?.data?.error || messages.responseError)
    }
  } finally {
    yield put(loading(false))
  }
}

export default function* rootSaga(): SagaIterator {
  yield all([
    takeLatest(actions.GET_USERS_REQUEST, getUsers),
    takeLatest(actions.UPDATE_USER_REQUEST, updateUser),
    takeLatest(actions.DELETE_USER_REQUEST, deleteUser),
    takeLatest(actions.GET_ACTIVE_USER_REQUEST, getActiveUser),
    takeLatest(actions.RESET_USER_PASSWORD_REQUEST, resetUserPassword),
    takeLatest(actions.ADD_NEW_USER, addNewUser),
  ])
}
