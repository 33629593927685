import { RoomType } from '@/redux/rooms/types'
import {
  allocateNormalize,
  basketNormalize,
  initAllocatedNormalize,
} from '@/redux/allocations/service'
import { IRootState } from '../reducers'

export const getBasket = (state: IRootState): Record<string, string[]> => {
  const { floor, status } = state.allocations.filters
  const basket = state.allocations.allocated?.basket ?? []
  const filtered = Object.values(state.allocations.rooms)
    .filter((room: RoomType) => {
      return (floor ? room.floor === floor : true) && (status ? room.status === status : true)
    })
    .reduce((acc: string[], room) => [...acc, room.id], [])

  return {
    basket: basket.filter((item) => filtered.includes(item)),
  }
}

export const getInitAllocatedState = (state: IRootState): Record<string, string[]> => {
  const initAllocated = initAllocatedNormalize(state.allocations.rooms)
  const allocated = allocateNormalize(state.allocations.attendants, initAllocated)

  allocated.basket = basketNormalize(state.allocations.rooms)
  return allocated
}

export const isChanged = (state: IRootState): boolean => {
  return Object.keys(state.allocations.history).length > 0
}
