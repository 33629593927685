export const IS_DEV = process.env.NODE_ENV === 'development'

export default {
  ROLE_ADMIN: 'Admin',
  ROLE_MANAGER: 'Manager',
}

export const occupiedTypes = ['OD', 'OC', 'OCI', 'DND', 'RS']

export enum Statuses {
  VD = 'Vacant Dirty',
  VC = 'Vacant Clean',
  VP = 'Vacant Pickup, To Be Inspected',
  VCI = 'Vacant Clean Inspected',
  OD = 'Occupied Dirty',
  OC = 'Occupied Clean',
  OCI = 'Occupied Clean Inspected',
  DND = 'Do Not Disturb',
  RS = 'Refused Service',
  OOO = 'Out Of Order',
}

export const RoomTypes = [
  'Double',
  'King',
  'King (patio)',
  'King (w/ snacks)',
  'King Ada (patio)',
  'Queen',
  'Queen (patio)',
  'Standard',
] as const

export const getRoomTypes = RoomTypes.map((item) => ({ id: item, name: item }), [])

export const CostLimit = {
  min: 0.5,
  max: 10,
}
