import { SagaIterator } from '@redux-saga/types'
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import messages from '@/config/messages'
import { loading as authLoading } from '@/redux/auth/actions'
import {
  fetchOne,
  fetchOneById,
  fetchFloorsById,
  fetchFloorWithRooms,
  getHotels as getHotelsService,
  getHotelGroups as getHotelGroupsService,
  editGeneralHotel as editGeneralHotelService,
  editHotelFloors as editHotelFloorsService,
  editHotelRoomsType as editHotelRoomsTypeService,
  deleteHotel as deleteHotelService,
  addNewHotel as addNewHotelService,
} from '@/services/hotels'
import { AnyAction } from 'redux'
import actions, {
  getHotelGroupsSuccess,
  getHotelsSuccess,
  loading,
  setState,
  setError,
} from './actions'

export function* getHotels(action: AnyAction): SagaIterator {
  yield put(authLoading(true))
  try {
    const { data } = yield call(getHotelsService, action.payload)
    yield put(getHotelsSuccess(data))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(authLoading(false))
  }
}

export function* getOne(): SagaIterator {
  yield put(loading(true))
  try {
    const { data } = yield call(fetchOne)
    yield put(setState({ hotel: data.hotel }))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

export function* getOneById({ id }: AnyAction): SagaIterator {
  yield put(loading(true))
  try {
    const { data } = yield call(fetchOneById, id)
    yield put(setState({ hotel: data }))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

export function* getFloorById({ id }: AnyAction): SagaIterator {
  yield put(loading(true))
  try {
    const { data } = yield call(fetchFloorsById, id)
    yield put(setState({ floors: data }))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

export function* getHotelFloorsWithRooms({ payload }: AnyAction): SagaIterator {
  yield put(loading(true))
  try {
    const { hotelId, floor } = payload
    const { data } = yield call(fetchFloorWithRooms, hotelId, floor)
    yield put(setState({ rooms: data || [] }))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

export function* getHotelGroups(): SagaIterator {
  yield put(loading(true))
  try {
    const { data } = yield call(getHotelGroupsService)
    yield put(getHotelGroupsSuccess(data))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

export function* addNewHotel(action: AnyAction): SagaIterator {
  yield put(loading(true))
  const { data, history } = action.data
  try {
    yield call(addNewHotelService, data)
    toast.success('Hotel was created!')
    history.goBack()
  } catch (e) {
    if (e?.response?.status === 400) {
      if (e?.response?.data?.error && typeof e?.response?.data?.error === 'object') {
        yield put(setError(e?.response?.data?.error))
        toast.error(messages.formHasErrors)
      } else {
        yield put(setError({}))
        toast.error(e?.response?.data?.error || messages.formHasErrors)
      }
    } else {
      toast.error(e?.response?.data?.error || messages.responseError)
    }
  } finally {
    yield put(loading(false))
  }
}

export function* editGeneralHotel({ payload }: AnyAction): SagaIterator {
  yield put(loading(true))
  try {
    const { data, id } = payload
    const response = yield call(editGeneralHotelService, id, data)
    yield put(setState({ hotel: response.data }))
    yield put(setError({}))
    toast.success('Hotel was updated!')
  } catch (e) {
    if (e?.response?.status === 400) {
      yield put(setError(e?.response?.data?.error))
    } else {
      toast.error(e?.response?.data?.error || messages.responseError)
    }
  } finally {
    yield put(loading(false))
  }
}

export function* editHotelFloors({ payload }: AnyAction): SagaIterator {
  yield put(loading(true))
  try {
    const { data, id } = payload

    yield call(editHotelFloorsService, id, data)

    yield put(setState({ floors: data.floors }))
    toast.success('Hotel floors was updated!')
  } catch (e) {
    if (e?.response?.status === 400) {
      yield put(setError(e?.response?.data?.error))
    } else {
      toast.error(e?.response?.data?.error || messages.responseError)
    }
  } finally {
    yield put(loading(false))
  }
}

export function* editHotelRoomsType({ data }: AnyAction): SagaIterator {
  yield put(loading(true))
  try {
    yield call(editHotelRoomsTypeService, data)
    yield put(setState({ rooms: data?.rooms || [] }))
    toast.success('Room types was updated!')
  } catch (e) {
    if (e?.response?.status === 400) {
      yield put(setError(e?.response?.data?.error))
    } else {
      toast.error(e?.response?.data?.error || messages.responseError)
    }
  } finally {
    yield put(loading(false))
  }
}

export function* deleteHotel({ payload }: AnyAction): SagaIterator {
  yield put(loading(true))
  try {
    const { history, id } = payload
    yield call(deleteHotelService, id)
    toast.success('Hotel was deleted!')
    history.replace('/admin/hotels/')
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

export default function* rootSaga(): SagaIterator {
  yield all([
    takeLatest(actions.GET_HOTELS_REQUEST, getHotels),
    takeLatest(actions.GET_ONE, getOne),
    takeLatest(actions.GET_ONE_BY_ID, getOneById),
    takeLatest(actions.GET_FLOORS_BY_ID, getFloorById),
    takeLatest(actions.GET_HOTEL_FLOORS_WITH_ROOMS, getHotelFloorsWithRooms),
    takeLatest(actions.GET_GROUPS_REQUEST, getHotelGroups),
    takeLatest(actions.ADD_NEW_HOTEL, addNewHotel),
    takeLatest(actions.EDIT_GENERAL_HOTEL, editGeneralHotel),
    takeLatest(actions.EDIT_HOTEL_FLOORS, editHotelFloors),
    takeLatest(actions.EDIT_HOTEL_ROOMS_TYPE, editHotelRoomsType),
    takeLatest(actions.DELETE_HOTEL, deleteHotel),
  ])
}
