import OAuth from '@DevimaSolutions/o-auth'
import { RefreshShiftsResponseType, ShiftsResponseType } from '@/redux/shifts/types'

export const fetchWorkShiftsList = (date?: number | string | Date): Promise<ShiftsResponseType> => {
  const filter = date ? `?date_start=${date}` : ''
  return OAuth()
    .axios.get(`/api/manager/workshifts${filter}`)
    .then(({ data }) => data?.shifts ?? [])
}

export const refreshWorkShiftsList = (): Promise<RefreshShiftsResponseType> => {
  return OAuth().axios.post(`/api/manager/refresh-workshifts`)
}
