import { AxiosResponse } from 'axios'
import OAuth from '@DevimaSolutions/o-auth'
import { CreateNoteType, EditRoomNoteType } from '@/redux/notes/types'
import qs from 'qs'

export const createNote = ({ data, id }: CreateNoteType): Promise<AxiosResponse<unknown>> =>
  OAuth().axios.post(`/api/room/notes/${id}`, data)

export const getRoomNotes = (
  id: string,
  lastId = null,
  limit = 20,
): Promise<AxiosResponse<unknown>> => {
  const filter = qs.stringify({ last_id: lastId, limit }, { skipNulls: true })
  return OAuth().axios.get(`/api/room/notes/${id}?${filter}`)
}

export const editRoomNote = ({ id, ...data }: EditRoomNoteType): Promise<AxiosResponse<unknown>> =>
  OAuth().axios.put(`/api/room/notes/${id}`, data)

export const deleteRoomNote = (id: string): Promise<AxiosResponse<unknown>> =>
  OAuth().axios.delete(`/api/room/notes/${id}`)
