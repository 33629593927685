import { SagaIterator } from '@redux-saga/types'
import { all, takeLatest, put, call } from 'redux-saga/effects'
import actions, { loading, saveShiftsList } from '@/redux/shifts/actions'
import { fetchWorkShiftsList, refreshWorkShiftsList } from '@/services/shifts'
import { toast } from 'react-toastify'
import messages from '@/config/messages'

export function* getShiftList(): SagaIterator {
  yield put(loading(true))
  try {
    const shifts = yield call(fetchWorkShiftsList, Date.now())
    yield put(saveShiftsList(shifts))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

export function* refreshShiftList(): SagaIterator {
  yield put(loading(true))
  try {
    yield call(refreshWorkShiftsList)
    const shifts = yield call(fetchWorkShiftsList, Date.now())
    yield put(saveShiftsList(shifts))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

export default function* rootSaga(): SagaIterator {
  yield all([takeLatest(actions.GET_SHIFT_LIST, getShiftList)])
  yield all([takeLatest(actions.REFRESH_SHIFT_LIST, refreshShiftList)])
}
