import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIconType } from '../types'

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}))

const SuccessfullyIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 46, 46',
  ...rest
}: SvgIconType) => {
  const classes = useStyles()
  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <circle cx="23" cy="23" r="23" fill="#6BB870" />
      <path
        d="M16 22L21 27L30 18"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default SuccessfullyIcon
