import { AnyAction } from 'redux'
import { UserType } from '@/redux/user/types'
import { ChangeStatusType, FloorType, RoomType } from './types'

const actions = {
  SET_STATE: 'rooms/SET_STATE',
  GET_ROOMS_LIST: 'rooms/GET_ROOMS_LIST',
  SAVE_FILTER: 'rooms/SAVE_FILTER',
  UPLOAD_CSV_FILE: 'rooms/UPLOAD_CSV_FILE',
  EXPORT_FILE: 'rooms/EXPORT_FILE',
  CLEAR_DATA: 'rooms/CLEAR_DATA',
  CHANGE_ROOM_STATUS: 'rooms/CHANGE_ROOM_STATUS',
  RETURN_STATUS_TO_ORDER: 'rooms/RETURN_STATUS_TO_ORDER',
  START_ROOMS_SOCKET_CHANNEL: 'rooms/START_ROOMS_SOCKET_CHANNEL',
  CLOSE_ROOMS_SOCKET_CHANNEL: 'rooms/CLOSE_ROOMS_SOCKET_CHANNEL',
  UPDATE_ROOMS_BY_SOCKET: 'rooms/UPDATE_ROOMS_BY_SOCKET',
  UPDATE_ROOM_BY_ID: 'rooms/UPDATE_ROOM_BY_ID',
  CLOSE_CSV_MODAL: 'rooms/CLOSE_CSV_MODAL',
}

export const setState = (payload: unknown): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload,
  }
}

export const loading = (data: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: { loading: data },
  }
}

export const uploading = (data: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: { uploading: data },
  }
}

export const saveRoomsFilter = (type: string, value: unknown): AnyAction => {
  return {
    type: actions.SAVE_FILTER,
    payload: { filter: { [type]: value } },
  }
}

export const saveRoomsList = (payload: { floors: FloorType[]; totalFloors: number }): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload,
  }
}

export const getRoomsList = (filter: { [k in string]: string | null }): AnyAction => {
  return {
    type: actions.GET_ROOMS_LIST,
    payload: { filter },
  }
}

export const uploadCsvFile = (file: File, onUploadSuccess?: () => void): AnyAction => {
  return {
    type: actions.UPLOAD_CSV_FILE,
    payload: { file, onUploadSuccess },
  }
}

export const exportCsvFile = (onSuccess: (data: Blob, fileName: string) => void): AnyAction => {
  return {
    type: actions.EXPORT_FILE,
    payload: { onSuccess },
  }
}

export const clearData = (): AnyAction => {
  return {
    type: actions.CLEAR_DATA,
  }
}

export const changeRoomStatus = (payload: ChangeStatusType): AnyAction => {
  return {
    type: actions.CHANGE_ROOM_STATUS,
    payload,
  }
}

export const startRoomsSocketChannel = (id?: string): AnyAction => {
  return {
    type: actions.START_ROOMS_SOCKET_CHANNEL,
    id,
  }
}

export const stopRoomsSocketChannel = (): AnyAction => {
  return {
    type: actions.CLOSE_ROOMS_SOCKET_CHANNEL,
  }
}

export const updateRoomById = (payload: RoomType): AnyAction => {
  return {
    type: actions.UPDATE_ROOM_BY_ID,
    payload,
  }
}

export const updateRoomsBySocket = (rooms: RoomType[]): AnyAction => {
  return {
    type: actions.UPDATE_ROOMS_BY_SOCKET,
    rooms,
  }
}

export const closeCsvModal = (data: UserType): AnyAction => {
  return {
    type: actions.CLOSE_CSV_MODAL,
    data,
  }
}

export default actions
