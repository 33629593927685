import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIconType } from '../types'

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}))

const RoomDetailsIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 48, 48',
  ...rest
}: SvgIconType) => {
  const classes = useStyles()
  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <path
        d="M18.9813 13.651H16.6473C15.542 13.651 14.6463 14.5475 14.6473 15.6528L14.6622 32.3487C14.6632 33.4526 15.5584 34.3469 16.6622 34.3469H31.3584C32.4636 34.3469 33.3594 33.4504 33.3584 32.3452L33.3434 15.6492C33.3424 14.5453 32.4473 13.651 31.3434 13.651H29.0507"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 12.651C19 12.0987 19.4477 11.651 20 11.651H28C28.5523 11.651 29 12.0987 29 12.651V14.651C29 15.2033 28.5523 15.651 28 15.651H20C19.4477 15.651 19 15.2033 19 14.651V12.651Z"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 21.6541H28"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 24.6891H27"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 27.6891H23"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default RoomDetailsIcon
