import { all, takeLatest, put, call } from 'redux-saga/effects'
import { SagaIterator } from '@redux-saga/types'
import { toast } from 'react-toastify'
import { AnyAction } from 'redux'
import { updateSelfPassword, updateSelf as update } from '@/services/user'
import messages from '@/config/messages'
import actions, { loading, mapUserData, setUserInfo } from './actions'

function* updateSelf(action: AnyAction): SagaIterator {
  yield put(loading(true))
  try {
    const { data } = yield call(update, action.data)
    yield put(mapUserData(action.data))
    yield put(setUserInfo(data))
    toast.success('User updated successfully')
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

function* updatePassword(action: AnyAction): SagaIterator {
  yield put(loading(true))

  try {
    yield call(updateSelfPassword, action.password)
    toast.success('Password updated successfully')
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

export default function* rootSaga(): SagaIterator {
  yield all([
    takeLatest(actions.UPDATE_SELF_REQUEST, updateSelf),
    takeLatest(actions.UPDATE_SELF_PASSWORD_REQUEST, updatePassword),
  ])
}
