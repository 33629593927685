import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIconType } from '../types'

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}))

const PenIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 28, 28',
  ...rest
}: SvgIconType) => {
  const classes = useStyles()
  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <path
        d="M22.9216 11.0504L24.8912 9.0808C25.2817 8.69028 25.2817 8.05711 24.8912 7.66659L20.3336 3.10896C19.943 2.71844 19.3099 2.71844 18.9194 3.10896L4.38767 17.6407L4.38767 23.6125L10.3595 23.6125L21.1194 12.8526L18.1335 9.86665"
        stroke="#1B1C1D"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default PenIcon
