import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIconType } from '../types'

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}))

const NotesIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 48, 48',
  fill = '#1B1C1D',
  ...rest
}: SvgIconType) => {
  const classes = useStyles()
  return (
    <SvgIcon {...rest} classes={classes} viewBox={viewBox}>
      <path
        d="M32.841 27.3592H28.341C27.2364 27.3592 26.341 28.2546 26.341 29.3592V33.8592M33.341 26.5308V15.6727C33.341 14.5681 32.4456 13.6727 31.341 13.6727L16.6611 13.6727C15.5566 13.6727 14.6611 14.5681 14.6611 15.6727L14.6611 32.3592C14.6611 33.4638 15.5566 34.3592 16.6611 34.3592H25.5126C26.043 34.3592 26.5517 34.1485 26.9268 33.7734L32.7552 27.945C33.1303 27.5699 33.341 27.0612 33.341 26.5308Z"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 18.6584H25"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 22.6584H22"
        stroke={fill}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default NotesIcon
