import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { SvgIconType } from '../types'

const CleaningIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 18, 18',
  fill = 'white',
  ...rest
}: SvgIconType) => {
  return (
    <SvgIcon {...rest} style={{ fill: 'none' }} viewBox={viewBox}>
      <g clipPath="url(#clip0)">
        <path
          d="M17 1.5L13 5.5"
          stroke={fill}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.07142 14.3703C6.68361 14.0405 7.2384 13.67 7.74092 13.2823"
          stroke={fill}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.14614 10.5875C3.84583 10.3389 4.34385 10.0667 4.84637 9.67895"
          stroke={fill}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 4.5L14 8.5M13.373 10.9788C12.4851 13.386 10.8846 15.3692 9 16.5C5.24847 14.4712 2.07449 10.5214 1.5 6C3.34289 6.47099 5.64048 6.26975 7.58264 5.46047"
          stroke={fill}
          strokeWidth="1.3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.7764 7.56002C14.5574 6.77897 14.5574 5.51264 13.7764 4.7316C12.9953 3.95055 11.729 3.95055 10.948 4.7316L13.7764 7.56002Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="18" height="18" fill={fill} transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export default CleaningIcon
