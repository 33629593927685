import { AnyAction } from 'redux'
import { call, put, take, select, takeLatest, spawn, race } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import {
  createNote as createNoteService,
  getRoomNotes as getRoomNotesService,
  editRoomNote as editRoomNoteService,
  deleteRoomNote as deleteRoomNoteService,
} from '@/services/notes'
import messages from '@/config/messages'
import { setState as setStateRoom } from '@/redux/room/actions'
import { getRoomPinnedNotes } from '@/services/rooms'
import { SagaIterator } from '@redux-saga/types'
import { IRootState } from '@/redux/reducers'
import { NotesDocType } from '@/redux/notes/types'
import { IS_DEV } from '@/config/constants'
import actions, {
  editRoomNoteSuccess,
  loading,
  setRoomNotes,
  deleteRoomNoteSuccess,
  addRoomNote,
} from './actions'
import eventChannel from './websocket'

export function* createNote({ payload }: AnyAction): SagaIterator {
  try {
    yield put(loading(true))
    const { data } = yield call(createNoteService, payload)
    yield put(addRoomNote([data]))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

export function* getRoomNotes({ payload }: AnyAction): SagaIterator {
  try {
    yield put(loading(true))
    const { id } = payload

    const { lastId, limit } = yield select((state: IRootState) => state.notes)
    const { data } = yield call(getRoomNotesService, id, lastId, limit)
    data.notes = data.notes.sort(
      (a: NotesDocType, b: NotesDocType) => Date.parse(a.time) - Date.parse(b.time),
    )
    yield put(setRoomNotes(data))
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

export function* editRoomNote({ payload }: AnyAction): SagaIterator {
  try {
    const { roomId, ...options } = payload
    const { data } = yield call(editRoomNoteService, options)
    yield put(editRoomNoteSuccess([data]))
    const { data: docs } = yield call(getRoomPinnedNotes, roomId)
    yield put(
      setStateRoom({
        pinned_note: docs,
      }),
    )
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

export function* deleteRoomNote({ payload }: AnyAction): SagaIterator {
  try {
    const { id, roomId } = payload
    yield call(deleteRoomNoteService, id)
    yield put(deleteRoomNoteSuccess(id))
    const { data: docs } = yield call(getRoomPinnedNotes, roomId)
    yield put(
      setStateRoom({
        pinned_note: docs,
      }),
    )
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

function* listenNotesSaga({ id }: AnyAction): SagaIterator {
  const notesChannel = yield call(eventChannel, id)
  try {
    while (true) {
      const action = yield take(notesChannel)
      yield put(action)
    }
  } catch (e) {
    if (IS_DEV) {
      // eslint-disable-next-line no-console
      console.log('listenNotesSagaError', {
        message: (e as Error).message,
      })
    }
    toast.error(messages.socketError)
  } finally {
    notesChannel?.close?.()
  }
}

function* websocketChannel(): SagaIterator {
  while (true) {
    const action = yield take(actions.START_NOTES_SOCKET_CHANNEL)
    try {
      yield race({
        task: call(listenNotesSaga, action),
        cancelTask: take(actions.CLOSE_NOTES_SOCKET_CHANNEL),
      })
    } catch (e) {
      if (IS_DEV) {
        // eslint-disable-next-line no-console
        console.log('watchNotesSagaError', {
          message: (e as Error).message,
        })
      }
      toast.error(messages.socketError)
    }
  }
}

export default function* rootSaga(): SagaIterator {
  yield spawn(websocketChannel)
  yield takeLatest(actions.CREATE_NOTE, createNote)
  yield takeLatest(actions.GET_ROOM_NOTES, getRoomNotes)
  yield takeLatest(actions.EDIT_ROOM_NOTE, editRoomNote)
  yield takeLatest(actions.DELETE_ROOM_NOTE, deleteRoomNote)
}
