import React, { useState } from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'
import { SvgIconType } from '../types'

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}))

const RoomIcon: React.FunctionComponent<SvgIconType> = ({
  viewBox = '0, 0, 34, 34',
  active,
  ...rest
}: SvgIconType) => {
  const classes = useStyles()
  const [hover, setHover] = useState(false)
  const strokeColor = hover ? '#1B1C1D' : '#53595C'
  return (
    <SvgIcon
      {...rest}
      classes={classes}
      viewBox={viewBox}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <path
        d="M3 20V22H31V20C31 18.3431 29.6569 17 28 17H6C4.34315 17 3 18.3431 3 20Z"
        fill="url(#paint0_linear_rooms)"
      />
      <path
        d="M29.3467 17.5834V11.3523C29.3467 10.4619 29.1679 9.52757 28.4265 9.03469C24.3454 6.32177 9.65452 6.32177 5.57344 9.03469C4.83198 9.52758 4.6532 10.4619 4.6532 11.3523C4.65319 14.4714 4.6532 17.5834 4.6532 17.5834"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 13C18.5 11.8954 19.3954 11 20.5 11H24.5C25.6046 11 26.5 11.8954 26.5 13V17H18.5V13Z"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 13C7.5 11.8954 8.39543 11 9.5 11H13.5C14.6046 11 15.5 11.8954 15.5 13V17H7.5V13Z"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
      <path
        d="M6.65527 17C4.99842 17 3.65527 18.3431 3.65527 20V26.3832H6.65527V24.3832H27.3401V26.3832H30.3401V20C30.3401 18.3431 28.9969 17 27.3401 17H6.65527Z"
        stroke={strokeColor}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      {active && (
        <defs>
          <linearGradient
            id="paint0_linear_rooms"
            x1="17"
            y1="17"
            x2="17"
            y2="22"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6BB870" />
            <stop offset="1" stopColor="#6BB870" stopOpacity="0" />
          </linearGradient>
        </defs>
      )}
    </SvgIcon>
  )
}

export default RoomIcon
