import { SagaIterator } from '@redux-saga/types'
import { AnyAction } from 'redux'
import { all, takeEvery, put, call } from 'redux-saga/effects'
import actions, {
  loading,
  setError,
  setPasswordExperationMessage,
  setPasswordConfirm,
} from '@/redux/auth/actions'
import {
  login as loginService,
  logout as logoutService,
  forgotPassword as forgotPasswordService,
  resetPassword as resetPasswordService,
} from '@/services/auth'
import { isMobile } from 'react-device-detect'
import { toast } from 'react-toastify'
import messages from '@/config/messages'
import redirectToLogin from '@/utils/redirect'

export function* login({ payload }: AnyAction): SagaIterator {
  yield put(loading(true))
  try {
    yield call(loginService, payload)
  } catch (e) {
    if (e?.response?.status === 400) {
      yield put(setError(e?.response?.data?.error))
    } else {
      toast.error(e?.response?.data?.error || messages.responseError)
    }
  } finally {
    yield put(loading(false))
  }
}

export function* logout(): SagaIterator {
  yield put(loading(true))
  try {
    yield call(logoutService)
  } catch (e) {
    toast.error(e?.response?.data?.error || messages.responseError)
  } finally {
    yield put(loading(false))
  }
}

export function* forgotPassword({ payload }: AnyAction): SagaIterator {
  yield put(loading(true))
  try {
    yield call(forgotPasswordService, payload)
    yield put(setPasswordConfirm(true))
  } catch (e) {
    if (e?.response?.status === 400) {
      yield put(setError(e?.response?.data?.error))
    } else {
      toast.error(e?.response?.data?.error || messages.responseError)
    }
  } finally {
    yield put(loading(false))
  }
}

export function* resetPassword({ payload }: AnyAction): SagaIterator {
  yield put(loading(true))
  try {
    yield call(resetPasswordService, payload)

    if (!isMobile) {
      toast.success('Password was reset!')
    }

    redirectToLogin(payload)
  } catch (e) {
    const errorMsg = e?.response?.data?.error

    if (errorMsg === 'The password reset link has expired.') {
      yield put(setPasswordExperationMessage(errorMsg))
    } else {
      toast.error(errorMsg || messages.responseError)
    }
  } finally {
    yield put(loading(false))
  }
}

export default function* rootSaga(): SagaIterator {
  yield all([
    takeEvery(actions.LOGIN, login),
    takeEvery(actions.LOGOUT, logout),
    takeEvery(actions.FORGOT_PASSWORD, forgotPassword),
    takeEvery(actions.RESET_PASSWORD, resetPassword),
  ])
}
